import React, { useState, createContext, useEffect } from 'react'
import { calculate } from "../TestData/data"

export const QuestionContext = createContext();

export const QuestionProvider = (props) => {

    const [questions, setQuestions] = useState([]);
    useEffect(() => {

        setQuestions(
            calculate.questions.map((question) => {
                return {
                    id: question.id,
                    label: question.label,
                    checked: '1',
                    name: question.label,
                    description: question.description,
                    type: question.type,
                    options: question.options.map((option) =>{                       
                        return {
                            id: option.id,
                            description: option.description,
                            size: option.description_position,
                            label: option.label,
                            icon: option.icon,
                            questionId: option.qestion_id,
                            position: option.postion,
                            selected: false,
                            isChecked: '0',
                            isSelection: '1',
                            subTotal: 0,
                            sections: option.sections.map((section)=> {
                                return {
                                    id: section.id,
                                    sectionId: section.section_id,
                                    optionId: section.id_option,
                                    price: section.price,
                                    multiplier: section.multiplier
                                }
                            })
                        }
                    })
                    
                        //prices: question.options.sections.map((section) => section.price),
                        //multipliers: question.options.sections.map((section) => section.multiplier )
                }
            })
        )
    }, [])

    const handleIsChecked = () => {
        setQuestions({selected: !questions.options.selected })
    }
    return ( 
        <QuestionContext.Provider value={[questions, setQuestions, handleIsChecked]}>
            { props.children } 
        </QuestionContext.Provider>
    )
}