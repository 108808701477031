import React from 'react'

const PrevArrow = (props) => {

    if (props.currentStep !== 0) {
        return (
            <div className="arrow-btn" onClick={props.onClick}>
                <img
                    src={props.icon}
                    className={`prev-btn ${props.currentStep >= 2 ? 'active' : ''}`}
                    alt=""
                />
            </div>
        )
    }
    return null;

}

export default PrevArrow