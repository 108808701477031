import React, { useState, useContext } from "react"
import RadioButton from "../components/RadioButton/RadioButton"
import NotRequired from "../components/Button/NotRequired"
import { AppContext } from "../contexts/AppContext"
import { QuestionContext } from "../contexts/QuestionContext"
import { SectionContext } from "../contexts/SectionContext"
import { OptionContext } from "../contexts/OptionContext"
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Page10 = (props) => {

    const matches = useMediaQuery('(max-width:640px)');
    const [page, setPage] = useContext(AppContext)

    const [questions, setQuestions] = useContext(QuestionContext)

    const { option, selected, checking2, required, selectFeature, optionPage10 } = useContext(SectionContext)
    const [selectionFeature, setSelectionFeature] = selectFeature;
    const [sections, setSections] = option
    const [sectionsPage10, setSectionsPage10] = optionPage10
    const [selection, setSelection] = selected
    const [checkButton2, setCheckButton2] = checking2
    const [isActivated, setIsActivated] = required
    const [checkedState, setCheckedState] = useState({ isChecked: false})

    const _next = () => {
        let currentStep = page.currentStep
        currentStep = currentStep >= 15 ? 16 : currentStep + 1
        setPage({ currentStep: currentStep })
    }
    if (props.currentStep !== 10) {
        return null
    }

    
    var requireBtn
    if (checkedState.isChecked === false) {
        requireBtn = <NotRequired
            currentStep={page.currentStep}
            onClick={_next}
            label="Not Required" />
    } else {
        requireBtn = null
    }
    return (
        questions.filter((questions) => questions.id === 10).map((question) => (
            <div key={question.id} className="question" /*style={{ transform: 'translate3d(0px, 0px, 0px)' }}*/>
                <h2>{question.label}</h2>
                <p style={{ paddingBottom: matches ? '3vh' : '6vh' }}>{question.description}</p>
                <div className="options-container">
                    {question.options.map((option) => (
                        <RadioButton
                            key={option.id}
                            type={question.type}
                            id={option.id}
                            name='myRadio'
                            value={option.id}
                            checked={checkButton2.isChecked === `${option.id}`}
                            //checked={checkButton.isChecked === '1' ? console.log("checked") : 'console.log(" no checked")'}
                            onClick={props.onClick}
                            onChange={(event) => {
                                let checked = event.target.value
                                let index

                                if (option.id === option.position) {
                                    option.isSelection = checked
                                    console.log(option.isSelection)
                                    setIsActivated({
                                        question10: event.target.checked
                                    })
                                    setCheckedState({
                                        isChecked: event.target.checked
                                    })                                    
                                }
                                setCheckButton2({
                                    isChecked: checked
                                })

                                //console.log(checked)
                                console.log("option selection:" + option.isSelection)
                                console.log("check button:" + checkButton2.isChecked)

                                option.sections.filter((sect) => sect.sectionId === parseInt(selectionFeature.isSelected)).map((section) => {
                                    if (section.optionId === option.id) {

                                        const cost1 = option.subTotal + (section.price * section.multiplier)
                                        index = sectionsPage10.indexOf({ name: option.label, cost: cost1 })
                                        sectionsPage10.splice(index, 1)

                                        const cost2 = option.subTotal + (section.price * section.multiplier)
                                        sectionsPage10.push({ name: option.label, cost: cost2 })
                                        console.log(sectionsPage10)
                                    }

                                })
                            }}
                            icon={option.icon}
                            label={option.label}
                            description={option.description}
                            style={{ left: option.size }}
                        />
                    ))}

                    {requireBtn}

                </div>
            </div>
        ))
    )
}
export default Page10