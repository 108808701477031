const back = require("./back.svg")
const next = require("./next.svg")
const design = require("./design.svg")
const dev = require("./dev.svg")
const both = require("./both.svg")
const logo = require("./Logo-sim.svg")
const appel = require("./appleicon.svg")
const android = require("./andriod.svg")
const mobile = require("./mobile.svg")
const watch = require("./watch.svg")
const tablet = require("./tablet.svg")
const mobiledev = require("./mobiledev.svg")
const web = require("./web.svg")
const hero = require("./hero.svg")
const nextarrow = require("./nextarrow.svg")
const people = require("./people.svg")
const checkmark = require("./Vector.svg")

export {
    back,
    next,
    design,
    dev,
    both,
    logo,
    appel,
    android,
    mobile,
    watch,
    tablet,
    mobiledev,
    web,
    hero,
    nextarrow,
    people,
    checkmark
}