import React, { useState, useContext } from "react"
import RadioButton from "../components/RadioButton/RadioButton"
import NotRequired from "../components/Button/NotRequired"
import { AppContext } from "../contexts/AppContext"
import { QuestionContext } from "../contexts/QuestionContext"
import { SectionContext } from "../contexts/SectionContext"
import { OptionContext } from "../contexts/OptionContext"
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Page5 = (props) => {

    const matches = useMediaQuery('(max-width:640px)');
    const [page, setPage] = useContext(AppContext)

    const [questions, setQuestions] = useContext(QuestionContext);
    const { option, selected, checking1, required, selectFeature, optionPage5, optionPage4 } = useContext(SectionContext);
    const [sections, setSections] = option;
    const [sectionsPage5, setSectionsPage5] = optionPage5;
    const [sectionsPage4, setSectionsPage4] = optionPage4;
    const [selection, setSelection] = selected;
    const [checkButton1, setCheckButton1] = checking1
    const [isActivated, setIsActivated] = required;
    const [selectionFeature, setSelectionFeature] = selectFeature;

    const [checkedState, setCheckedState] = useState({ isChecked: false})

    const _next = () => {
        let currentStep = page.currentStep
        currentStep = currentStep >= 15 ? 16 : currentStep + 1
        setPage({ currentStep: currentStep })
    }

    if (props.currentStep !== 5) {
        return null
    }

    
    var requireBtn
    if (checkedState.isChecked === false) {
        requireBtn = <NotRequired
            currentStep={page.currentStep}
            onClick={_next}
            label="Not Required" />
    } else {
        requireBtn = null
    }

    return (
        questions.filter((questions) => questions.id === 5).map((question) => (
            <div key={question.id} className="question" /*style={{ transform: 'translate3d(0px, 0px, 0px)' }}*/>
                <h2>{question.label}</h2>
                <p style={{ paddingBottom: matches ? '3vh' : '6vh' }}>{question.description}</p>
                <div className="options-container">
                    {question.options.map((option) => (
                        <RadioButton
                            key={option.id}
                            type={question.type}
                            id={option.id}
                            name='myRadio'
                            value={option.id}
                            checked={checkButton1.isChecked === `${option.id}`}
                            //checked={checkButton.isChecked === '1' ? console.log("checked") : 'console.log(" no checked")'}
                            onClick={props.onClick}
                            onChange={(event) => {
                                let checked = event.target.value
                                let index

                                if (option.id === option.position) {
                                    option.isSelection = checked
                                    console.log(option.isSelection)
                                    setIsActivated({
                                        question5: event.target.checked
                                    })
                                    setCheckedState({
                                        isChecked: event.target.checked
                                    })                                    
                                }
                                setCheckButton1({
                                    isChecked: checked
                                })

                                //console.log(checked)
                                console.log("option selection:" + option.isSelection)
                                console.log("check button:" + checkButton1.isChecked)

                                option.sections.filter((sect) => sect.sectionId === parseInt(selectionFeature.isSelected)).map((section) => {
                                    if (section.optionId === option.id) {

                                        const cost1 = option.subTotal + (section.price * section.multiplier)
                                        index = sectionsPage5.indexOf({ name: option.label, cost: cost1 })
                                        sectionsPage5.splice(index, 1)

                                        const cost2 = option.subTotal + (section.price * section.multiplier)
                                        
                                        sectionsPage5.push({ name: option.label, cost: cost2 })
                                        
                                        //console.log(optionPage4)
                                    }

                                })
                            }}
                            icon={option.icon}
                            label={option.label}
                            description={option.description}
                            style={{ left: option.size}}
                        />
                    ))}

                    {requireBtn}

                </div>
            </div>
        ))
    )
}
export default Page5