import React, { useState, useContext } from "react"
import RadioButton from "../components/RadioButton/RadioButton"
import {
    design,
    dev,
    both
} from "../assets/IconLibrary"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SectionContext } from "../contexts/SectionContext"
import { QuestionContext } from "../contexts/QuestionContext"
import { AppContext } from "../contexts/AppContext"

const Page1 = (props) => {

    const [questions, setQuestions] = useContext(QuestionContext)
    const matches = useMediaQuery('(max-width:640px)');

    const { selected, required, optionFeatured } = useContext(SectionContext);
    const [selection, setSelection] = selected;
    const [isActivated, setIsActivated] = required;
    const [optionFeature, setOptionFeature] = optionFeatured;



    if (props.currentStep !== 1) {
        return null
    }
    return (


        questions.filter((questions) => questions.id === 1).map((question) => (
            <div key={question.id} className="question" /*style={{ transform: 'translate3d(0px, 0px, 0px)' }}*/>
                <h2>{question.label}</h2>
                <p style={{ paddingBottom: matches ? '5vh' : '15vh' }}>{question.description}</p>

                <div className="options-radio options-section">
                    {question.options.map((option) => (

                        // sections.map(section => (
                        <RadioButton
                            key={option.id}
                            type={question.type}
                            id={option.id}
                            name='myRadio'
                            value={option.id}
                            checked={selection.isSelected === `${option.id}`}
                            onChange={(event) => {
                                let checked = event.target.value
                                let index

                                if (option.id === option.position) {
                                    option.isChecked = checked
                                    //console.log(option.isChecked)
                                }
                                setSelection({
                                    isSelected: checked
                                })
                                setIsActivated({
                                    question1: event.target.checked
                                })
                                
                                if (option.id === 1) {
                                    index = optionFeature.indexOf({ name: option.label })
                                    optionFeature.splice(index, 1)
                                    console.log(optionFeature)
                                    //setOptionFeature([{name: ""}])
                                    
                                    optionFeature.push({ name: option.label })
                                    console.log(optionFeature)
                                   
                                } else if(option.id === 2){                                   
                                    index = optionFeature.indexOf({ name: option.label })
                                    optionFeature.splice(index, 1)
                                    console.log(optionFeature)
                                    //setOptionFeature([{name: ""}])
                                    
                                    optionFeature.push({ name: option.label })
                                    console.log(optionFeature)
                                } else if(option.id === 3){                                   
                                    index = optionFeature.indexOf({ name: option.label })
                                    optionFeature.splice(index, 1)
                                    console.log(optionFeature)
                                    //setOptionFeature([{name: ""}])
                                    
                                    optionFeature.push({ name: option.label })
                                    console.log(optionFeature)
                                }
                                //console.log(selection.isSelected)   
                            }
                        }
                            icon={option.icon}
                            label={option.label}
                            description={option.description}
                        />
                        // ))

                    ))}
                </div>
            </div>

        ))
    )
}



export default Page1