import React from 'react'
import { Link } from "react-router-dom"

const NotRequired = (props) => {
    if (props.currentStep < 16) {
        return (
                <Link
                    className="btn-not-required"
                    onClick={props.onClick}
                    style={props.style}
                    to="">
                    {props.label && (
                        <span className="label-not-required">{props.label}</span>
                    )}
                </Link>
        )
    }
    return null;
}

export default NotRequired