import React, { useState, createContext } from 'react'

export const SectionContext = createContext();

export const SectionProvider = (props) => {
    const [sections, setSections] = useState([])
    const [sectionsPage4, setSectionsPage4] = useState([])
    const [sectionsPage5, setSectionsPage5] = useState([])
    const [sectionsPage10, setSectionsPage10] = useState([])
    const [sectionCoreFeatures, setsectionCoreFeatures] = useState([])
    const [selection, setSelection] = useState({isSelected: '0'})
    const [selectionFeature, setSelectionFeature] = useState([{isSelected: '0'}])
    const [checkButton, setCheckButton] = useState({ isChecked: '0'})
    const [checkButton1, setCheckButton1] = useState({ isChecked: '0'})
    const [checkButton2, setCheckButton2] = useState({ isChecked: '0'})
    const [optionsType, setoptionsType] = useState('')
    const [feature, setFeature] = useState([])
    const [optionFeature, setOptionFeature] = useState([])
    const [coreElement, setElement] = useState([])
    const [isActivated, setIsActivated] = useState({
        question1: false,
        question2: false,
        question3: false,
        question4: false,
        question5: false,
        question6: false,
        question7: false,
        question8: false,
        question9: false,
        question10: false,
        question11: false,
        question12: false,
        question13: false,
        question14: false,
        question15: false,
        question16: false,
    })

    return (
        <SectionContext.Provider value={{option: [sections, setSections],
        coreService: [sectionCoreFeatures, setsectionCoreFeatures],
        optionFeatured: [optionFeature, setOptionFeature],
        selectFeature: [selectionFeature, setSelectionFeature],  
        selected: [selection, setSelection], 
        featured: [feature, setFeature], 
        checking: [checkButton, setCheckButton],
        checking1: [checkButton1, setCheckButton1],
        checking2: [checkButton2, setCheckButton2],
        options:[optionsType, setoptionsType], 
        coreSystem:[coreElement, setElement], 
        required: [isActivated, setIsActivated],
        optionPage4: [sectionsPage4, setSectionsPage4],
        optionPage5: [sectionsPage5, setSectionsPage5],
        optionPage10: [sectionsPage10, setSectionsPage10]
        }}>
            {props.children}
        </SectionContext.Provider>
    )
}