import React, { useContext, useEffect, useState } from 'react'
import { checkmark } from "../assets/IconLibrary"
import InputText from "../components/InputText/InputText"
import { SectionContext } from "../contexts/SectionContext"
import { AppContext } from "../contexts/AppContext"
import { useHistory } from "react-router-dom"

const Invoice = (props) => {
	const [page, setPage] = useContext(AppContext)
	const { featured, option, selected, checking, options, coreSystem, optionPage4, optionPage5, optionPage10, coreService, selectFeature } = useContext(SectionContext)
	const [sections, setSections] = option
	const [selection, setSelection] = selected
	const [feature, setFeature] = featured
	const [checkButton, setCheckButton] = checking
	const [optionsType, setoptionsType] = options
	const [coreElement, setElement] = coreSystem
	const [sectionsPage4, setSectionsPage4] = optionPage4
    const [sectionsPage5, setSectionsPage5] = optionPage5
	const [sectionsPage10, setSectionsPage10] = optionPage10
	const [sectionCoreFeatures, setsectionCoreFeatures] = coreService
	const [selectionFeature, setSelectionFeature] = selectFeature

	let history = useHistory();

	useEffect(() => {
		if (page.currentStep === 16) {
			const timer = setTimeout(() => {
				//history.push('/signup')
				let currentStep = page.currentStep
				currentStep = currentStep >= 15 ? 16 : currentStep + 1
				setPage({ currentStep: currentStep })
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [page.currentStep]);

	// useEffect(() => {
	// 	 setTimeout(() => {
	// 		history.push('/signup')
	// 	 }, 2000);


	//   }, []);


	
	// var sections1 = sections.push(... sectionsPage4)
	// var sections2 = sections1.push(sectionsPage5)
	// var sections3 = sections2.push(sectionsPage10)
	
	//var sections1 = sections.splice(1, 0, sectionsPage4)
	// var sections2 = sections1.splice(2, 0, sectionsPage5)
	// var sections3 = sections2.splice(7, 0, sectionsPage10)

	const move = (arr, old_index, new_index) => {
		while (old_index < 0) {
			old_index += arr.length;
		}
		while (new_index < 0) {
			new_index += arr.length;
		}
		if (new_index >= arr.length) {
			var k = new_index - arr.length;
			while ((k--) + 1) {
				arr.push(undefined);
			}
		}
		 arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
	   return arr;
	}

	

	var sections1 = [ ...sectionsPage4, ...sectionsPage5];
	var sections2 = [ ...sections1, ...sections];
	var sections3 = [...sections2, ...sectionsPage10];
	//var sections3 = sections4.splice(1, 0, sectionsPage10)
	//move()
	const costCoreElementEstimate = () => {
		var sum = 0
		var item = 0
		var total = 0
		var formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		sections3.forEach(element => {

			sum += parseInt(element.cost)

		})

		coreElement.forEach(element => {

			if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 1){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 1){
				item = 28
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 1){
				item = 18
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 1){
				item = 28
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 1){
				item = 18
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 1){
				item = 46
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 2){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 30
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 4){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 4){
				item = 20
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 4){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 4){
				item = 20
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 4){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 4){
				item = 35
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 3){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 3){
				item = 43
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 3){
				item = 33
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 3){
				item = 43
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 3){
				item = 33
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 3){
				item = 76
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 5){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 5){
				item = 40
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 5){
				item = 30
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 5){
				item = 40
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 5){
				item = 30
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 70
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}
			

		})

		return total
		//return sum
	}
	const costCoreServiceEstimate = () => {
		var sum = 0
		var formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		sectionCoreFeatures.forEach(element => {

			sum += parseInt(element.cost)
		})

         
		// return formatter.format(sum + 4600)
		return sum + 2850
		//return sum
		
	}
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	const costEstimate = () => {
		var sum = 0
		var formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		sections3.forEach(element => {

			sum += parseInt(element.cost)
		})

		// return formatter.format(sum + 4600)
		const estimated = sum + costCoreServiceEstimate()
		return formatter.format(estimated)
		//return sum
		
	}
	const costCoreFeatures = () => {
		var sum = 0
		var formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		sections3.forEach(element => {

			sum += parseInt(element.cost)
		})

		// return formatter.format(sum + 4600)
		return formatter.format(sum)
		//return sum
		
	}

	const costCoreService = () => {
		var sum = 0
		var formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});
		sectionCoreFeatures.forEach(element => {

			sum += parseInt(element.cost)
		})

         
		// return formatter.format(sum + 4600)
		return formatter.format(sum + 2850)
		//return sum
		
	}

	const costEstimateOperating = () => {
		var sum = 0
		var formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});

		sections.forEach(element => {
			if (page.currentStep === 14) {
				sum += parseInt(element.cost)
			}
		})

		return formatter.format(sum)
		//return sum
	}

	const costEstimateValue = () => {
		var sum = 0
		sections3.forEach(element => {

			sum += parseInt(element.cost)
		})

		// return formatter.format(sum + 4600)
		const estimated = sum + costCoreServiceEstimate()
		return estimated
		//return sum
		
	}

	const costCoreElement = () => {
		var sum = 0
		var item = 0
		var total = 0
		

		coreElement.forEach(element => {

			if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 1){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 1){
				item = 28
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//total = sum - cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 1){
				item = 18
				const cost = (costEstimateValue() * item) / 100
				total = cost
				console.log(total +"Cost :"+ cost +"sum"+ sum)
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 1){
				item = 28
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 1){
				item = 18
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 1){
				item = 46
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 2){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 30
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 4){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 4){
				item = 20
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 4){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 4){
				item = 20
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 4){
				item = 15
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 4){
				item = 35
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 3){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 3){
				item = 43
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 3){
				item = 33
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 3){
				item = 43
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 3){
				item = 33
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 3){
				item = 76
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && parseInt(selectionFeature.isSelected) === 5){
				item = 0
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 5){
				item = 40
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Watch" && parseInt(selectionFeature.isSelected) === 5){
				item = 30
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && parseInt(selectionFeature.isSelected) === 5){
				item = 40
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 5){
				item = 30
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}else if(element.name === "Mobile" && element.name === "Tablet" && element.name === "Watch" && parseInt(selectionFeature.isSelected) === 2){
				item = 70
				const cost = (costEstimateValue() * item) / 100
				total = cost
				//sum += parseInt(element.cost)
			}
			

		})

		return formatter.format(total)
		
		//return sum
	}
	const getOperatingSystem = () => {
		var operator = []
		feature.forEach(element => {

			operator.push(element.name + ", ")
		})
		return operator
		//return sum
	}

	

	// const [sections, setSections] = useContext(SectionContext)
	if (props.currentStep !== 16) {
		return null
	}
	// console.log(sections.sum)
	return (
		<React.Fragment>
			
			<div id="invoice">
				<div className="toolbar hidden-print">
					<div className="text-right">
						{/* <button id="printInvoice" className="btn btn-info"><i className="fa fa-print"></i> Print</button>
                    <button className="btn btn-info"><i className="fa fa-file-pdf-o"></i> Export as PDF</button> */}
					</div>

				</div>
				<div className="invoice overflow-auto">
					<div style={{ minWidth: '600px' }}>
						<header>
							<div className="row">
								<div className="col">
									<h3>
										{optionsType}

									</h3>
									<br />
									<p>Please note that this estimted is based on limited<br />
                                amount of infomration, It’s possible that the final price<br />
varys based on the finer details of the project</p><br />
									<h3>Prepaired For:</h3>
									<h3 syle={{fontWeight: 'bold'}}>Full Name</h3>
									{/* <InputText
										type="text"
										typeInput="style-input"
										onChange={() => console.log("change me")}
										value="text"
										placeholder="Name"
										title="fullname"
										id="fullname"
										name="fullname"
									/> */}
								</div>

								<div className="col company-details">
									{/* <h2 className="name">
                                    <a target="_blank" href="https://lobianijs.com">
                                        Arboshiki
                            </a>
                                </h2>
                                <div>455 Foggy Heights, AZ 85004, US</div>
                                <div>(123) 456-789</div>
                                <div>company@example.com</div> */}
								</div>
							</div>
						</header>
						<main>
							<div className="row contacts">
								<div className="col invoice-to">
									{/* <div className="text-gray-light">INVOICE TO:</div>
                                <h2 className="to">John Doe</h2>
                                <div className="address">796 Silver Harbour, TX 79273, US</div>
                                <div className="email"><a href="mailto:john@example.com">john@example.com</a></div> */}
								</div>
								<div className="col invoice-details">
									<div className="date">Estimated Cost<br /></div>
									<h1>{costEstimate()}</h1>
									<div className="date"></div>
									{/* <h1 className="invoice-id">${sections.subTotal}</h1> */}

									{/* <div className="date">Due Date: 30/10/2018</div> */}
								</div>
							</div>
							<table border="0" cellspacing="0" cellpadding="0" style={{ marginTop: '170px' }}>
								<thead>
									<tr>
										<th></th>
										{/* <th className="text-left">Core Elements</th>
                                    <th className="text-right"></th>
                                    <th className="text-right"></th>
                                    <th className="text-right" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>DESIGN & DEVELOPMENT</th> */}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="no"></td>
										<td className="text-left"><h2>
											Core Elements
                                    </h2>

										</td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>
											{(selection.isSelected === '1') ? ('DESIGN') : ((selection.isSelected === '2') ? ('DEVELOPMENT') : ('DESIGN & DEVELOPMENT'))}
										</td>
									</tr>
									<tr>
										<td className="no"></td>
										<td className="text-left"><h3>
											Operating System: {getOperatingSystem()}
										</h3>

										</td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total"><img src={checkmark} alt="" /></td>
											{/* {costCoreElement()} */}
											
									</tr>
									{coreElement.map((element, index) => (
										<tr key={index}>
											<td className="no"></td>
											<td className="text-left"><h3>{element.name}</h3></td>
											<td className="unit"></td>
											<td className="qty"></td>
											<td className="total"><img src={checkmark} alt="" /></td>
										</tr>
									))}

									{/* <tr>
									<td className="no"></td>
									<td className="text-left"><h3>Tablet</h3></td>
									<td className="unit"></td>
									<td className="qty"></td>
									<td className="total"><img src={checkmark} alt="" /></td>
								</tr>
								<tr>
									<td className="no"></td>
									<td className="text-left"><h3>Watch</h3></td>
									<td className="unit"></td>
									<td className="qty"></td>
									<td className="total"><img src={checkmark} alt="" /></td>
								</tr> */}
									<tr></tr>

									<tr >
										<td className="no"></td>
										<td className="text-left" style={{ height: '40px', width: '100%', background: '#F2F5FB' }}><h3>Subtotal</h3></td>
										<td className="unit" style={{ background: '#F2F5FB' }}></td>
										<td className="qty" style={{ background: '#F2F5FB' }}></td>
										<td className="total" style={{ background: '#F2F5FB' }}>{costCoreElement()}</td>
									</tr>
								</tbody>
								{/* <tfoot>
                            
                                <tr>
                                <td colspan="2">SUBTOTAL</td>
                                    <td colspan="2"></td>
                                    <td>$5,200.00</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td colspan="2">TAX 25%</td>
                                    <td>$1,300.00</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td colspan="2">GRAND TOTAL</td>
                                    <td>$6,500.00</td>
                                </tr>
                            </tfoot> */}
							</table>


							<table border="0" cellspacing="0" cellpadding="0">
								<thead>
									<tr>
										<th></th>
										{/* <th className="text-left">Core Elements</th>
                                    <th className="text-right"></th>
                                    <th className="text-right"></th>
                                    <th className="text-right" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>DESIGN & DEVELOPMENT</th> */}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="no"></td>
										<td className="text-left"><h2>
											Core Features
                                    </h2>

										</td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>
											{(selection.isSelected === '1') ? ('DESIGN') : ((selection.isSelected === '2') ? ('DEVELOPMENT') : ('DESIGN & DEVELOPMENT'))}
										</td>
									</tr>
									{sections3.map((option, index) => (

										<tr key={index}>
											<td className="no"></td>
											<td className="text-left"><h3>
												{option.name}
											</h3>

											</td>
											<td className="unit"></td>
											<td className="qty"></td>
											<td className="total">{formatter.format(option.cost)}</td>
										</tr>


									))}
									<tr >
										<td className="no"></td>
										<td className="text-left" style={{ height: '40px', width: '100%', background: '#F2F5FB' }}><h3>Subtotal</h3></td>
										<td className="unit" style={{ background: '#F2F5FB' }}></td>
										<td className="qty" style={{ background: '#F2F5FB' }}></td>
									    <td className="total" style={{ background: '#F2F5FB' }}>{costCoreFeatures()}</td>
									</tr>

									{/* <tr>
									<td className="no"></td>
									<td className="text-left"><h3>Image editing</h3></td>
									<td className="unit"></td>
									<td className="qty"></td>
									<td className="total">$4,000</td>
								</tr>
								<tr>
									<td className="no"></td>
									<td className="text-left"><h3>Apply Filters or Effects</h3></td>
									<td className="unit"></td>
									<td className="qty"></td>
									<td className="total">$5,000</td>
								</tr>


								<tr >
									<td className="no"></td>
									<td className="text-left" style={{ height: '40px', width: '100%', background: '#F2F5FB' }}><h3>Subtotal</h3></td>
									<td className="unit" style={{ background: '#F2F5FB' }}></td>
									<td className="qty" style={{ background: '#F2F5FB' }}></td>
									<td className="total" style={{ background: '#F2F5FB' }}>$7,000</td>
								</tr> */}


								</tbody>
								{/* <tfoot>
                            
                                <tr>
                                <td colspan="2">SUBTOTAL</td>
                                    <td colspan="2"></td>
                                    <td>$5,200.00</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td colspan="2">TAX 25%</td>
                                    <td>$1,300.00</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td colspan="2">GRAND TOTAL</td>
                                    <td>$6,500.00</td>
                                </tr>
                            </tfoot> */}
							</table>

							<table border="0" cellspacing="0" cellpadding="0">
								<thead>
									<tr>
										<th></th>
										{/* <th className="text-left">Core Elements</th>
                                    <th className="text-right"></th>
                                    <th className="text-right"></th>
                                    <th className="text-right" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>DESIGN & DEVELOPMENT</th> */}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="no"></td>
										<td className="text-left"><h2>
											Core Services
                                    </h2>
											<p>
												The core services contribute to the success of each project built at Blank Space.<br /> The core services are applied automatically on every project.
                                    </p>

										</td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>
											{(selection.isSelected === '1') ? ('DESIGN') : ((selection.isSelected === '2') ? ('DEVELOPMENT') : ('DESIGN & DEVELOPMENT'))}
										</td>
									</tr>
									<tr>
										<td className="no"></td>
										<td className="text-left"><h3>
											Planning and project setup
                                    </h3>

										</td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total">$750</td>
									</tr>
									<tr>
										<td className="no"></td>
										<td className="text-left"><h3>App Store submission</h3></td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total">$1,200</td>
									</tr>
									<tr>
										<td className="no"></td>
										<td className="text-left"><h3>Project managment / QA</h3></td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total">$900</td>
									</tr>
									{sectionCoreFeatures.map((option, index) => (
									<tr key={index}>
										<td className="no"></td>
										<td className="text-left"><h3>{option.name === "I care about the branding aspect" ? "Digital Brand Development" : option.name}</h3></td>
										<td className="unit"></td>
										<td className="qty"></td>
										<td className="total">{formatter.format(option.cost)}</td>
									</tr>
									))}
									<tr >
										<td className="no"></td>
										<td className="text-left" style={{ height: '40px', width: '100%', background: '#F2F5FB' }}><h3>Subtotal</h3></td>
										<td className="unit" style={{ background: '#F2F5FB' }}></td>
										<td className="qty" style={{ background: '#F2F5FB' }}></td>
										<td className="total" style={{ background: '#F2F5FB' }}>{costCoreService()}</td>
									</tr>


								</tbody>
								{/* <tfoot>
                            
                                <tr>
                                <td colspan="2">SUBTOTAL</td>
                                    <td colspan="2"></td>
                                    <td>$5,200.00</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td colspan="2">TAX 25%</td>
                                    <td>$1,300.00</td>
                                </tr>
                                <tr>
                                    <td colspan="2"></td>
                                    <td colspan="2">GRAND TOTAL</td>
                                    <td>$6,500.00</td>
                                </tr>
                            </tfoot> */}

							</table>
							<table border="0" cellspacing="0" cellpadding="0">
								<thead>
									<tr>
										<th></th>
										{/* <th className="text-left">Core Elements</th>
                                    <th className="text-right"></th>
                                    <th className="text-right"></th>
                                    <th className="text-right" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>DESIGN & DEVELOPMENT</th> */}
									</tr>
								</thead>
								<tbody>

									<tr >
										<td className="no"></td>
										<td className="text-left" style={{ height: '83px', width: '100%', background: '#3677FF', borderRadius: '4px' }}><h2 style={{ color: '#FFFFFF', paddingLeft: "20px" }}>Estimated Total</h2></td>
										{/* <td className="unit" style={{ background: '#3677FF' }}></td>
									<td className="qty" style={{ background: '#3677FF' }}></td> */}
										<td className="total" style={{ background: '#3677FF', color: '#FFFFFF', paddingRight: "20px", borderRadius: '4px' }}>{costEstimate()}</td>
									</tr>


								</tbody>
							</table>
							{/* <div className="thanks">Thank you!</div> */}
							{/* <div className="notices">
                            <div>NOTICE:</div>
                            <div className="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
                        </div> */}
						</main>
						<footer>
						</footer>
					</div>
					<div></div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default Invoice