import React from 'react'

const Checkbox = (props) => {

    return (
        <>
            <label className="container-checkbox">
                
                {props.icon && (
                    <img src={props.icon} className="checkbox-icon" alt="" />
                )}
                {props.label && (
                    <span className="checkbox-label">{props.label}</span>
                )}
                <input
                    type={props.type}
                    id={props.id}
                    value={props.value}
                    name={props.name}
                    checked={props.checked}
                    onChange={props.onChange}
                />
                
                <span className="checkbox-checkmark"></span>
                {props.description && (
                <div className="description" style={props.style}>{props.description}</div>
            )}
                
            </label>
            

        </>
    )

}

export default Checkbox
