import React, { useContext, useEffect } from 'react'
import { checkmark } from "../assets/IconLibrary"
import { SectionContext } from "../contexts/SectionContext"
import { AppContext } from "../contexts/AppContext"
//import { useHistory } from "react-router-dom"

const Test = (props) => {
  // const [page, setPage] = useContext(AppContext)
  // const { featured, option, selected, checking, options, coreSystem } = useContext(SectionContext)
  // const [sections, setSections] = option
  // const [selection, setSelection] = selected
  // const [feature, setFeature] = featured
  // const [checkButton, setCheckButton] = checking
  // const [optionsType, setoptionsType] = options
  // const [coreElement, setElement] = coreSystem

  // //let history = useHistory();

  // useEffect(() => {
  // 	if (page.currentStep === 16) {
  // 		const timer = setTimeout(() => {
  // 			//history.push('/signup')
  // 			let currentStep = page.currentStep
  // 			currentStep = currentStep >= 15 ? 16 : currentStep + 1
  // 			setPage({ currentStep: currentStep })
  // 		}, 2000);
  // 		return () => clearTimeout(timer);
  // 	}
  // }, [page.currentStep]);

  // // useEffect(() => {
  // // 	 setTimeout(() => {
  // // 		history.push('/signup')
  // // 	 }, 2000);


  // //   }, []);

  // const costEstimate = () => {
  // 	var sum = 0
  // 	var formatter = new Intl.NumberFormat('en-US', {
  // 		style: 'currency',
  // 		currency: 'USD',
  // 	});
  // 	sections.forEach(element => {
  // 		sum += parseInt(element.cost)
  // 	})
  // 	return formatter.format(sum + 4600)
  // }


  // const costEstimateOperating = () => {
  // 	var sum = 0
  // 	var formatter = new Intl.NumberFormat('en-US', {
  // 		style: 'currency',
  // 		currency: 'USD',
  // 	});


  // 	sections.forEach(element => {
  // 		if (page.currentStep === 14) {
  // 			sum += parseInt(element.cost)
  // 		}
  // 	})

  // 	return formatter.format(sum)

  // }

  // const costCoreElement = () => {
  // 	var sum = 0
  // 	var formatter = new Intl.NumberFormat('en-US', {
  // 		style: 'currency',
  // 		currency: 'USD',
  // 	});
  // 	coreElement.forEach(element => {
  // 		sum += parseInt(element.cost)
  // 	})
  // 	return formatter.format(sum)
  // }
  // const getOperatingSystem = () => {
  // 	var operator = []
  // 	feature.forEach(element => {
  // 		operator.push(element.name + ", ")
  // 	})
  // 	return operator
  // }

  // if (props.currentStep !== 16) {
  // 	return null
  // }

  return (
    <div className="quote overflow-auto">
      <div className="head">
        <div className="row">
          <div className="col">
            <h3>test</h3>
            <br />
            <p>Please note that this estimted is based on limited<br />
                amount of infomration, It’s possible that the final price<br />
                varys based on the finer details of the project</p><br />
            <h3>Prepaired For:</h3>
            <h3 syle={{ fontWeight: 'bold' }}>Full Name</h3>
          </div>

          <div className="row contacts">
            <div className="col invoice-details">
              <div className="date">Estimated Cost<br /></div>
              {/* <h1>{costEstimate()}</h1> */}
              <h1>$500</h1>
              <div className="date"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="sperator"></div>
      <div className="elements">
        <table border="0" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="no"></td>
              <td className="text-left"><h2>Core Elements</h2></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>
                Design
											{/* {(selection.isSelected === '1') ? ('DESIGN') : ((selection.isSelected === '2') ? ('DEVELOPMENT') : ('DESIGN & DEVELOPMENT'))} */}
              </td>
            </tr>
            <tr>
              <td className="no"></td>
              <td className="text-left"><h3>
                Operating System: IOS
                      {/* {getOperatingSystem()} */}
              </h3>

              </td>
              <td className="unit"></td>
              <td className="qty"></td>
              {/* <td className="total">{costCoreElement()}</td> */}
              <td className="total">$7,000</td>
            </tr>
            {/* {coreElement.map((element, index) => ( */}
            {/* <tr key={index}> */}
            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>
            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>
            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>
            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>
            {/* ))}							 */}
            <br />
            <tr >
              <td className="no"></td>
              <td className="text-left" style={{ height: '40px', width: '100%', background: '#F2F5FB' }}><h3>Subtotal</h3></td>
              <td className="unit" style={{ background: '#F2F5FB' }}></td>
              <td className="qty" style={{ background: '#F2F5FB' }}></td>
              <td className="total" style={{ background: '#F2F5FB' }}>$7,000</td>
              {/* <td className="total" style={{ background: '#F2F5FB' }}>{costCoreElement()}</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="features">
        <table border="0" cellspacing="0" cellpadding="0">
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="no"></td>
              <td className="text-left"><h2>Core Elements</h2></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total" style={{ fontSize: '11px', lineHeight: '136.9%', color: '#858585' }}>
                Design
											{/* {(selection.isSelected === '1') ? ('DESIGN') : ((selection.isSelected === '2') ? ('DEVELOPMENT') : ('DESIGN & DEVELOPMENT'))} */}
              </td>
            </tr>
            <tr>
              <td className="no"></td>
              <td className="text-left"><h3>
                Operating System: IOS
                      {/* {getOperatingSystem()} */}
              </h3>

              </td>
              <td className="unit"></td>
              <td className="qty"></td>
              {/* <td className="total">{costCoreElement()}</td> */}
              <td className="total">$7,000</td>
            </tr>
            {/* {coreElement.map((element, index) => ( */}
            {/* <tr key={index}> */}
            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>

            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>

            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>

            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>

            <tr>
              <td className="no"></td>
              {/* <td className="text-left"><h3>{element.name}</h3></td> */}
              <td className="text-left"><h3>Test</h3></td>
              <td className="unit"></td>
              <td className="qty"></td>
              <td className="total"><img src={checkmark} alt="" /></td>
            </tr>
            {/* ))}							 */}
            <tr></tr>
            <tr >
              <td className="no"></td>
              <td className="text-left" style={{ height: '40px', width: '100%', background: '#F2F5FB' }}><h3>Subtotal</h3></td>
              <td className="unit" style={{ background: '#F2F5FB' }}></td>
              <td className="qty" style={{ background: '#F2F5FB' }}></td>
              <td className="total" style={{ background: '#F2F5FB' }}>$7,000</td>
              {/* <td className="total" style={{ background: '#F2F5FB' }}>{costCoreElement()}</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="services">4</div>
      <div className="total">5</div>
    </div>
  )
}

export default Test
