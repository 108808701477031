import React, { useContext, useState } from "react"
import Checkbox from "../components/Checkbox/CheckBox"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { QuestionContext } from "../contexts/QuestionContext"
import { SectionContext } from "../contexts/SectionContext"
import { OptionContext } from "../contexts/OptionContext"
import { AppContext } from "../contexts/AppContext"


const Page2 = (props) => {

    const [question2, setquestion2] = useState([]);

    const matches = useMediaQuery('(max-width:640px)');
    const [questions, setQuestions] = useContext(QuestionContext);

    const [value, setValue] = useState();
    const [options, setOptions] = useState({ id: 0, isChecked: false })
    const [page, setPage] = useContext(AppContext)

    const { featured, required, optionFeatured, selected, selectFeature } = useContext(SectionContext);
    const [feature, setFeature] = featured;
    const [isActivated, setIsActivated] = required;
    const [optionFeature, setOptionFeature] = optionFeatured;
    const [selection, setSelection] = selected;
    const [selectionFeature, setSelectionFeature] = selectFeature;


    if (props.currentStep !== 2) {
        return null
    }

    return (
        questions.filter(questions => questions.id === 2).map((question) => (
            <div key={question.id} className="question"/*style={{ transform: 'translate3d(0px, 0px, 0px)' }}*/>
                <h2>{question.label}</h2>
                <p style={{ paddingBottom: matches ? '5vh' : '15vh' }}>{question.description}</p>
                <div className="options-checkbox">
                    {question.options.map((option) => (

                        <Checkbox
                            key={option.id}
                            type={question.type}
                            id={option.id}
                            name={option.selected}
                            value={option.selected}
                            //checked={option.selected || false}
                            onChange={(e) => {
                                let checked = e.target.checked
                                let check = option.id 
                                let index


                                if (option.id === option.position) {
                                    option.selected = checked
                                    setIsActivated({
                                        question2: checked
                                    })
                                    check = check + 1
                                }

                            

                                console.log("checked nbr:" + check)
                                
                                optionFeature.map((features) => {

                                    if (features.name === "Design" && option.id === 1 && check === 2) {

                                        setSelectionFeature({ isSelected: 4 })
                                        console.log(selectionFeature.isSelected)

                                    } else if (features.name === "Design" && option.id === 2 && check === 2) {

                                        setSelectionFeature({ isSelected: 4 })
                                        console.log(selectionFeature.isSelected)

                                    }else if (features.name === "Design" && check === 3) {

                                        setSelectionFeature({ isSelected: 5 })
                                        console.log(selectionFeature.isSelected)

                                    } else if (features.name === "Development" && option.id === 1 && check === 2) {

                                        setSelectionFeature({ isSelected: 1 })
                                        console.log(selectionFeature.isSelected)

                                    } else if (features.name === "Development" && option.id === 2 && check === 2) {

                                        setSelectionFeature({ isSelected: 2 })
                                        console.log(selectionFeature.isSelected)

                                    } else if (features.name === "Development" && check === 3) {

                                        setSelectionFeature({ isSelected: 3 })
                                        console.log(selectionFeature.isSelected)

                                    }else if (features.name === "Both"  && option.id === 1 && check === 2) {

                                        setSelectionFeature({ isSelected: 6 })
                                        console.log(selectionFeature.isSelected)

                                    }else if (features.name === "Both"  && option.id === 2 && check === 2) {

                                        setSelectionFeature({ isSelected: 6 })
                                        console.log(selectionFeature.isSelected)

                                    }else if (features.name === "Both" && check === 3) {

                                        setSelectionFeature({ isSelected: 6 })
                                        console.log(selectionFeature.isSelected)

                                    }

                                    // if (feature.name === "Design" && option.id === 1) {
                                    //     setSelectionFeature({ isSelected: 4 })                                            
                                    // }else if (feature.name === "Design" && option.id === 2) {
                                    //     setSelectionFeature({ isSelected: 4 })                                            
                                    // } else if (feature.name === "Development" && option.id === 1) {
                                    //     setSelectionFeature({ isSelected: 2 })
                                    //     console.log(selectionFeature.isSelected)
                                    //     console.log(option.id)
                                    // } else if (feature.name === "Development" && option.id === 2) {
                                    //     setSelectionFeature({ isSelected: 1 })
                                    //     console.log(selectionFeature.isSelected)
                                    // } else if (feature.name === "Development" &&  option.id === 1 && option.id === 2) {
                                    //     setSelectionFeature({ isSelected: 3 })
                                    //     console.log(selectionFeature.isSelected)
                                    //     console.log("Two options selected")
                                    // }else if (feature.name === "Both") {
                                    //     setSelectionFeature({ isSelected: 5 })
                                    //     console.log(selectionFeature.isSelected)
                                    // }else {
                                    //     setSelectionFeature({ isSelected: 0 })
                                    // } 

                                })

                                if (option.selected === true) {
                                    feature.push({ name: option.label })
                                    //setSelectionFeature({isSelected: 4})
                                     
                                    console.log(feature)

                                } else {
                                    index = feature.indexOf({ name: option.label })
                                    feature.splice(index, 1)
                                    console.log(feature)
                                    
                                    //setSelectionFeature({isSelected: 0})
                                }
                                console.log(optionFeature)
                                console.log(selectionFeature)

                            }}
                            icon={option.icon}
                            label={option.label}
                            description={option.description}

                        />
                    ))}
                </div>
            </div>
        ))
    )
}
export default Page2


// (event) => {
//     let checked = event.target.checked
//     if (option.id === option.postion)
//     question.selected = checked;
//     setOptions({ isChecked: checked })
//     console.log(checked)
//     onsole.log(option.id+" "+option.postion)
//}