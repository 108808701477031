import React, { useState, useContext } from "react"
import ButtonCard from "../components/Card/ButtonCard"
import IconLibrary from "../assets/IconLibrary/IconLibrary"
import { AppContext } from "../contexts/AppContext"
import { people } from "../assets/IconLibrary"
import { SectionContext } from "../contexts/SectionContext"

//import { hero } from "../assets/IconLibrary"

const Page = (props) => {
    const [option, setOption] = useState({ selected: '' })

    const [page, setPage] = useContext(AppContext)

    const { options } = useContext(SectionContext);
    const [optionsType, setoptionsType] = options

    const _next = () => {
        let currentStep = page.currentStep
        currentStep = currentStep >= 15 ? 16 : currentStep + 1
        setPage({ currentStep: currentStep })
    }

    const handleChange = (e) => {
        setOption({ selected: e.target.value })
        console.log(option.selected)
        // if()
    }
    const handleClick = (e) => {
        setOption({ selected: e.target.value })
        console.log(option.selected)
        if (e.target.value === 'Mobile Application') {
            setoptionsType('Mobile Application')
        } else {
            setoptionsType('Web Application')
        }
        console.log(optionsType)
        _next()
    }

    if (props.currentStep !== 0) {
        return null
    }
    return (
        <>
            <div className="question-intro">
                <h3 style={{ textAlign: 'center' }}>How much could your project cost?</h3>
                <p style={{ textAlign: 'center' }}>Easily calculate the costs of your project with just a few clicks.
            <br />For a precise calculation, only include necessary features.</p>

                <div className="card-options options-section">
                    <ButtonCard
                        type='radio'
                        id='Mobile Application'
                        name='myRadio'
                        value='Mobile Application'
                        checked={option.selected === 'Mobile Application'}
                        onChange={handleChange}
                        onClick={handleClick}
                        label="Mobile Application"
                        icon={
                            <IconLibrary
                                width="76"
                                height="44"
                                color="blue"
                                name="mobile"
                                viewBox='0 0 48 52'
                                className="icon-color"
                            />
                        }
                    />

                    <ButtonCard
                        type='radio'
                        id='Web Application'
                        name='myRadio'
                        value='Web Application'
                        defaultChecked={option.selected === 'Web Application'}
                        onChange={handleChange}
                        onClick={handleClick}
                        icon={
                            <IconLibrary
                                width="76"
                                height="44"
                                color="blue"
                                name="web"
                                className="icon-color"
                                viewBox="0 0 77 45"
                            />
                        }
                        label="Web Application"
                        className="icon-color"
                    />


                </div>

            </div>
            <div className="estimated-count">
                <img src={people} alt=""></img>
                <span>4695 People have estimated their project here </span>
            </div>
        </>
    )
}
export default Page