import React, { useState, useContext } from "react"
import {
    mobile,
    watch,
    tablet
} from "../assets/IconLibrary"
import Checkbox from "../components/Checkbox/CheckBox"
import { QuestionContext } from "../contexts/QuestionContext"
import { SectionContext } from "../contexts/SectionContext"
import { OptionContext } from "../contexts/OptionContext"
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Page14 = (props) => {

    const matches = useMediaQuery('(max-width:640px)');
    const [questions, setQuestions] = useContext(QuestionContext);
    // const [sections, setSections] = useContext(SectionContext)
    const [options, setOptions] = useState({ isChecked: false })
    const { option, coreSystem, required, selectFeature } = useContext(SectionContext);
    const [selectionFeature, setSelectionFeature] = selectFeature;
    const [sections, setSections] = option;
    const [coreElement, setElement] = coreSystem;
    const [isActivated, setIsActivated] = required;
    const [checkedState, setCheckedState] = useState({ isChecked: false })

    if (props.currentStep !== 15) {
        return null
    }


    var requireBtn
    if (checkedState.isChecked === false) {
        requireBtn = <span className="btn-selected-option">Select At Least One Option</span>
    } else {
        requireBtn = null
    }
    return (

        questions.filter(questions => questions.id === 14).map((question) => (
            <div key={question.id} className="question" /*style={{ transform: 'translate3d(0px, 0px, 0px)' }}*/>
                <h2>{question.label}</h2>
                <p style={{ paddingBottom: matches ? '5vh' : '15vh' }}></p>
                <div className="options-radio">


                    {question.options.map((option) => (
                        <Checkbox
                            key={option.id}
                            type={question.type}
                            id={option.id}
                            name={option.selected}
                            value={option.selected}
                            //checked={option.selected || false}
                            onChange={(e) => {
                                let checked = e.target.checked
                                let index
                                let sum = 0
                                let check = option.id
                                let item


                                if (option.id === option.position) {
                                    option.selected = checked
                                    setIsActivated({
                                        question14: checked
                                    })
                                    setCheckedState({
                                        isChecked: checked
                                    })
                                    check = check + 1
                                }

                                setOptions({ id: option.id, isChecked: !option.selected })
                                //console.log(sections)
                                console.log(coreElement)
                                sections.forEach(element => {

                                    sum += parseInt(element.cost)

                                })
                                console.log("checked nbr:" + check)
                                // option.sections.filter((sect) => sect.sectionId === parseInt(selectionFeature.isSelected)).map((section) => {
                                // option.sections.map((section) => {    
                                if (checked === true && option.id === 1 && check ===2) {
                                  
                                    coreElement.push({ name: option.label })
                                    console.log(coreElement)
                                    console.log(check)

                                } else if (checked === true && option.id === 2 && check ===3) {
                                   
                                    coreElement.push({ name: option.label })
                                    console.log(coreElement)
                                    console.log(check)

                                } else if (checked === true && option.id === 3 && check === 4) {
                                    
                                    coreElement.push({ name: option.label })
                                    console.log(coreElement)
                                    console.log(check)
 
                                } else if(checked === false){
                                   
                                    index = coreElement.indexOf({ name: option.label })
                                    coreElement.splice(index, 1)
                                    console.log(coreElement)
                                    console.log("uni Checked")
                                }

                                // })
                            }}
                            icon={option.icon}
                            label={option.label}
                            description={option.description}

                        />
                    ))}
                    {requireBtn}
                </div>
            </div>
        ))
    )
}

export default Page14