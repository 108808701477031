import React, { useState, createContext, useEffect } from 'react'
import { calculate } from "../TestData/data"

export const OptionContext = createContext();

export const OptionProvider = (props) => {

    const [options, setOptions] = useState([]);
    setOptions({ isChecked: false })
    return (
        <OptionContext.Provider value={[options, setOptions]}>
            {props.children}
        </OptionContext.Provider>
    )
}