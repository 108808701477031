import React, { useState, createContext } from 'react'

export const AppContext = createContext();

export const AppProvider = (props) => {
    const [page, setPage] = useState({
        currentStep: 0,
        isSelected: '1'
    })

return(
    <AppContext.Provider value={[page, setPage]}>
        {props.children}
    </AppContext.Provider>
)
}