import React from 'react'
import { Link } from "react-router-dom"

const SubmitButton = (props) => {

    if (props.currentStep < 16) {
        
        return (
                <Link
                    className="btn submit"
                    onClick={props.onClick}
                    to=""
                    style={props.color}
                    >
                    {props.label && (
                        <span>{props.label}</span>
                    )}                  
                </Link>
        )
    }
    return null;
}

export default SubmitButton