import React from "react"

const RadioButton = (props) => {
    return (
        <>
            <label className="container-radio">
                {props.icon && (
                    <img src={props.icon} className="radio-icon" alt="" />
                )}
                {props.label && (
                    <span className="radio-label with-img">{props.label}</span>
                )}

                <input
                    type={props.type}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onClick={props.onClick}
                    checked={props.checked}
                    onChange={props.onChange}
                />
                <span className="checkmark"></span>
                {props.description && (
                <div className="description" style={props.style}>{props.description}</div>
            )}
            </label>
            
            
        </>
    )
}

export default RadioButton
