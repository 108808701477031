import React from 'react'
import { Link } from "react-router-dom"

const NotRequiredButton = (props) => {

    if (props.currentStep < 16) {
        
        return (
                <Link
                    className="btn-required next-required"
                    onClick={props.onClick}
                    to=""
                    style={props.color}
                    >
                    {props.label && (
                        <span>{props.label}</span>
                    )}
                    {props.icon && (
                        <img src={props.icon} style={{position: 'absolute', left: '88.85%'}} alt=""/>
                    )}
                    
                </Link>
        )
    }
    return null;
}

export default NotRequiredButton