export const calculate = ({
    sections: [{
            "id": 1,
            "name": "Design",
        },
        {
            "id": 2,
            "name": "Development",
        },
        {
            "id": 3,
            "name": "Both",
        }
    ],
    questions: [{
            "id": 1,
            "label": "What services are you interested in?",
            "type": "radio",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 1,
                    "label": "Design",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 1,
                    "icon": require("../assets/IconLibrary/design.svg"),
                    "sections": []
                },
                {
                    "id": 2,
                    "qestion_id": 1,
                    "label": "Development",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 2,
                    "icon": require("../assets/IconLibrary/dev.svg"),
                    "sections": []
                },
                {
                    "id": 3,
                    "qestion_id": 1,
                    "label": "Both",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 3,
                    "icon": require("../assets/IconLibrary/both.svg"),
                    "sections": []
                }
            ]
        },
        /* Question 1 */
        {
            "id": 2,
            "label": "How many platforms do you plan to support?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 1,
                    "label": "Google Android",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 1,
                    "icon": require("../assets/IconLibrary/andriod.svg"),
                    "sections": []
                },
                {
                    "id": 2,
                    "qestion_id": 1,
                    "label": "Apple iOS",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 2,
                    "icon": require("../assets/IconLibrary/apple.svg"),
                    "sections": []
                }
            ]
        },
        /* Question 2*/
        {
            "id": 3,
            "label": "How do you need your users to sign-up?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 2,
                    "label": "Simple signup (Email & Password)",
                    "description": "Users only need to fill in their email and password to sign up to your platform",
                    "description_position": "373px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 22,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 40,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 8,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 48,
                            "multiplier": 65,
                        }

                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 2,
                    "label": "Sign up with social media accounts",
                    "description": "Allow users to sign up to your platform with socail media accounts such as, Google, Facebook and Twitter",
                    "description_position": "384px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 12,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 8,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 26,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 2,
                    "label": "Two step authentication",
                    "description": "Require users to authenicate their account by verifying their identify with verifcation code",
                    "description_position": "288px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 10,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 8,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 24,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 3 */
        {
            "id": 4,
            "label": "Do your users need to have personal profile?",
            "type": "radio",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 4,
                    "label": "Simple Profile",
                    "description": "Display basic user information only",
                    "description_position": "191px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 65,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 30,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 95,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 15,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 30,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 110,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 4,
                    "label": "Complex Profile",
                    "description": "include advanced components to profile such as posts, reviews history...etc",
                    "description_position": "212px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 80,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 50,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 130,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 25,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 50,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 155,
                            "multiplier": 65,
                        }

                    ]
                }
            ],
            features: [],
        },

        /* Question 4 */
        {
            "id": 5,
            "label": "Do you need to integrate maps or geolocation?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 4,
                    "label": "Simple map integration",
                    "description": "Display map with basic geo based pins",
                    "description_position": "293px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 30,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 48,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 30,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 60,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 78,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 4,
                    "label": "Geo-fencing",
                    "description": "Allow the app to accommodate dynamic results within specified geo parameters. Useful for based search, socail feed on map and more",
                    "description_position": "180px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 17,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 29,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 35,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 4,
                    "label": "Real time tracking",
                    "description": "Ability to track real time location of selected users for meaningful purpose. For example: show curior or driver on the map",
                    "description_position": "232px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 44,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 30,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 60,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 85,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 5 */
        {
            "id": 6,
            "label": "Do your users need to work with images?",
            "type": "checkbox",
            "description": "if your app is social platfom, your users will likely require working with images. Capture and upload will allow you to upload an image",

            "options": [{
                    "id": 1,
                    "qestion_id": 5,
                    "label": "Capture and upload images",
                    "description": "",
                    "description_position": "319px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 8,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 14,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 15,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 30,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 37,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 5,
                    "label": "Image Editing",
                    "description": "",
                    "description_position": "187px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 36,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 10,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 46,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 5,
                    "label": "Create Collections (Albums)",
                    "description": "",
                    "description_position": "324px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 16,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 34,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 40,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 4,
                    "qestion_id": 5,
                    "label": "Apply Filters Or Effects",
                    "description": "",
                    "description_position": "275px",
                    "active": 1,
                    "postion": 4,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 4,
                            "price": 24,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 4,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 4,
                            "price": 42,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 4,
                            "price": 17,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 4,
                            "price": 34,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 4,
                            "price": 59,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 6 */
        {
            "id": 7,
            "label": "Do your users need to work with Video or Audio?",
            "type": "checkbox",
            "description": "If your app is social platfom, your users will likely require working with images. Capture and upload will allow you to upload an image",

            "options": [{
                    "id": 1,
                    "qestion_id": 6,
                    "label": "Video Recording",
                    "description": "",
                    "description_position": "219px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 15,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 27,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 30,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 60,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 57,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 6,
                    "label": "Stream video / audio",
                    "description": "",
                    "description_position": "265px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 30,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 25,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 55,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 27,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 54,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 82,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 6,
                    "label": "Live broadcasting",
                    "description": "",
                    "description_position": "228px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 55,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 22,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 44,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 77,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 4,
                    "qestion_id": 6,
                    "label": "Video Editing",
                    "description": "",
                    "description_position": "187px",
                    "active": 1,
                    "postion": 4,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 4,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 4,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 4,
                            "price": 55,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 4,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 4,
                            "price": 24,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 4,
                            "price": 67,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 7 */
        {
            "id": 8,
            "label": "Do you need any socail features?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 7,
                    "label": "Comment, Like, Share, Save",
                    "description": "",
                    "description_position": "223px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 8,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 14,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 9,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 23,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 7,
                    "label": "Newsfeed or social wall",
                    "description": "",
                    "description_position": "277px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 36,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 9,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 45,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 7,
                    "label": "Users taging",
                    "description": "",
                    "description_position": "180px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 16,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 34,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 2,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 4,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 36,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 4,
                    "qestion_id": 7,
                    "label": "Location check-ins",
                    "description": "",
                    "description_position": "233px",
                    "active": 1,
                    "postion": 4,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 4,
                            "price": 24,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 4,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 4,
                            "price": 42,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 4,
                            "price": 17,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 4,
                            "price": 34,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 4,
                            "price": 59,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },

        /* Question 8 */
        {
            "id": 9,
            "label": "Do you want users to communicate?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 8,
                    "label": "Simple notifcations inbox",
                    "description": "",
                    "description_position": "290px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 8,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 14,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 9,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 23,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 8,
                    "label": "Live text chat",
                    "description": "",
                    "description_position": "187px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 36,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 10,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 46,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 8,
                    "label": "Voice and video calls",
                    "description": "",
                    "description_position": "257px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 16,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 34,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 2,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 4,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 36,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 4,
                    "qestion_id": 8,
                    "label": "Group chat",
                    "description": "",
                    "description_position": "165px",
                    "active": 1,
                    "postion": 4,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 4,
                            "price": 24,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 4,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 4,
                            "price": 42,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 4,
                            "price": 17,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 4,
                            "price": 34,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 4,
                            "price": 93,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 9 */
        {
            "id": 10,
            "label": "Do you need to accept payments?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 9,
                    "label": "Integrate with mobile POS (paypal)",
                    "description": "Allow users to make payment via paypal",
                    "description_position": "402px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 30,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 50,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 25,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 50,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 75,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 9,
                    "label": "Handle and store card data directly",
                    "description": "Allow users to have a saved payment option within your platform for easier checkout experience",
                    "description_position": "396px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 53,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 22,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 44,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 75,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 9,
                    "label": "Using a gateway's API",
                    "description": "Allow users to make payments by visa card, master card",
                    "description_position": "272px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 53,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 9,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 62,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },

        /* Question 10 */
        {
            "id": 11,
            "label": "Any event planning tools?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 10,
                    "label": "Calendar",
                    "description": "Allow user to check calandar and detailed information.",
                    "description_position": "145px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 8,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 14,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 32,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 22,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 10,
                    "label": "Ticket Management",
                    "description": "Allow users manage their digital tickets on the platform",
                    "description_position": "234px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 36,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 9,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 45,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 10,
                    "label": "Appointment scheduling",
                    "description": "Allow user to book an appointment through the platform.",
                    "description_position": "289px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 16,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 34,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 2,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 4,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 36,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 11 */
        {
            "id": 12,
            "label": "Any other features?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 11,
                    "label": "QR scanner and coupons",
                    "description": "Allow user to scan QR code to fetch results",
                    "description_position": "293px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 15,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 27,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 32,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 43,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 11,
                    "label": "Rating or reviews",
                    "description": "Allow user to rate products and services or write comments",
                    "description_position": "221px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 30,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 25,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 55,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 40,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 75,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 11,
                    "label": "Loyalty Program",
                    "description": "Users can earn points within your platform based specified activities",
                    "description_position": "221px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 53,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 22,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 44,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 75,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 4,
                    "qestion_id": 11,
                    "label": "Search",
                    "description": "Allows users to search for different things within your platform",
                    "description_position": "126px",
                    "active": 1,
                    "postion": 4,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 4,
                            "price": 29,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 4,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 4,
                            "price": 49,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 4,
                            "price": 4,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 4,
                            "price": 8,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 4,
                            "price": 53,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 5,
                    "qestion_id": 11,
                    "label": "Push Notification",
                    "description": "Send event based or announcement-like push notifications",
                    "description_position": "221px",
                    "active": 1,
                    "postion": 5,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 5,
                            "price": 18,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 5,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 5,
                            "price": 36,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 5,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 5,
                            "price": 24,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 5,
                            "price": 48,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 6,
                    "qestion_id": 11,
                    "label": "E-commerce",
                    "description": "Allow users to shop for products on your platform",
                    "description_position": "179px",
                    "active": 1,
                    "postion": 6,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 6,
                            "price": 28,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 6,
                            "price": 22,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 6,
                            "price": 50,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 6,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 6,
                            "price": 32,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 6,
                            "price": 66,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 7,
                    "qestion_id": 11,
                    "label": "Calculator",
                    "description": "Perform mathematical calculations based on user data or input fields",
                    "description_position": "156px",
                    "active": 1,
                    "postion": 7,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 7,
                            "price": 12,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 7,
                            "price": 8,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 7,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 7,
                            "price": 10,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 7,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 7,
                            "price": 30,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 8,
                    "qestion_id": 11,
                    "label": "Shipping Modual",
                    "description": "Direct integration with shipping carriers to fetch pricing and estimated timelines",
                    "description_position": "217px",
                    "active": 1,
                    "postion": 8,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 8,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 8,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 8,
                            "price": 53,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 8,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 8,
                            "price": 24,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 8,
                            "price": 65,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 9,
                    "qestion_id": 11,
                    "label": "In-app browser",
                    "description": "A web viewer that provides limited browsing functionality",
                    "description_position": "202px",
                    "active": 1,
                    "postion": 9,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 9,
                            "price": 20,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 9,
                            "price": 15,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 9,
                            "price": 35,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 9,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 9,
                            "price": 12,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 9,
                            "price": 41,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 10,
                    "qestion_id": 11,
                    "label": "News / Blog",
                    "description": "Users can read articles from your platform.",
                    "description_position": "174px",
                    "active": 1,
                    "postion": 10,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 10,
                            "price": 15,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 10,
                            "price": 11,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 10,
                            "price": 26,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 10,
                            "price": 8,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 10,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 10,
                            "price": 34,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 12 */
        {
            "id": 13,
            "label": "What kind of admin capabilities do you require?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 12,
                    "label": "Content manamgnet tool",
                    "description": "Allows you to manage your platform content with ease",
                    "description_position": "297px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 35,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 18,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 53,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 20,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 40,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 73,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 12,
                    "label": "User managment tool",
                    "description": "Manage & control your community with advanced user management tool",
                    "description_position": "266px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 23,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 17,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 40,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 15,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 30,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 55,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 12,
                    "label": "Reporting and Analytics",
                    "description": "Get specific data report and analytics of key metrics about your platform",
                    "description_position": "285px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 16,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 13,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 29,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 3,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 32,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 45,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },

        /* Question 13 */
        {
            "id": 14,
            "label": "What device would you like to support?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 13,
                    "label": "Mobile",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 1,
                    "icon": require("../assets/IconLibrary/mobile.svg"),
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 0
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 0,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 0,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 13,
                    "label": "Tablet",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 2,
                    "icon": require("../assets/IconLibrary/tablet.svg"),
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 28
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 15
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 4,
                            "price": 0,
                            "multiplier": 20,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 13,
                    "label": "Watch",
                    "description": "",
                    "description_position": "",
                    "active": 1,
                    "postion": 3,
                    "icon": require("../assets/IconLibrary/watch.svg"),
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 0,
                            "multiplier": 18
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 0,
                            "multiplier": 15,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 0,
                            "multiplier": 15,
                        }
                    ]
                }
            ],
            features: [],
        },
        /* Question 14 */
        {
            "id": 15,
            "label": "How important is the app look and feel to you?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 14,
                    "label": "I care about the branding aspect",
                    "description": "Focus on improving the brand recognition, brand image and brand personality",
                    "description_position": "366px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 24,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 1,
                            "price": 48,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 1,
                            "price": 24,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 14,
                    "label": "Custom Design",
                    "description": "Get a complete custom design of your app",
                    "description_position": "202px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 2,
                            "price": 16,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 2,
                            "price": 32,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 2,
                            "price": 16,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 14,
                    "label": "Animated Design",
                    "description": "animation effects and interactive experiences for increased engagment",
                    "description_position": "223px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 6,
                            "multiplier": 65,
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 4,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 10,
                            "multiplier": 65,
                        },
                        {
                            "id": 4,
                            "section_id": 4,
                            "id_option": 1,
                            "price": 24,
                            "multiplier": 65,
                        },
                        {
                            "id": 5,
                            "section_id": 5,
                            "id_option": 3,
                            "price": 48,
                            "multiplier": 65,
                        },
                        {
                            "id": 6,
                            "section_id": 6,
                            "id_option": 3,
                            "price": 34,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        },

        /* Question 15 */
        {
            "id": 16,
            "label": "Do your users need to have personal profile?",
            "type": "checkbox",
            "description": "",

            "options": [{
                    "id": 1,
                    "qestion_id": 15,
                    "label": "Simple Profile",
                    "description": "",
                    "description_position": "373px",
                    "active": 1,
                    "postion": 1,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 1,
                            "price": 0,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 2,
                    "qestion_id": 15,
                    "label": "Complex Profile",
                    "description": "",
                    "description_position": "373px",
                    "active": 1,
                    "postion": 2,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 2,
                            "price": 0,
                            "multiplier": 65,
                        }
                    ]
                },
                {
                    "id": 3,
                    "qestion_id": 15,
                    "label": "Two step authentication",
                    "description": "",
                    "description_position": "373px",
                    "active": 1,
                    "postion": 3,
                    "sections": [{
                            "id": 1,
                            "section_id": 1,
                            "id_option": 3,
                            "price": 0,
                            "multiplier": 65
                        },
                        {
                            "id": 2,
                            "section_id": 2,
                            "id_option": 3,
                            "price": 0,
                            "multiplier": 65,
                        },
                        {
                            "id": 3,
                            "section_id": 3,
                            "id_option": 3,
                            "price": 0,
                            "multiplier": 65,
                        }
                    ]
                }
            ],
            features: [],
        }
    ]
})