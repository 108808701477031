import React from 'react';
import LandingPageForm from "./layout/LandingPageForm"
import Header from "./components/Header/Header"
import { logo } from "./assets/IconLibrary"
import { AppProvider } from "./contexts/AppContext"
import { QuestionProvider } from "./contexts/QuestionContext"
import { SectionProvider } from "./contexts/SectionContext"
import { OptionProvider } from "./contexts/OptionContext"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Signup } from "./pages"
import Test from './pages/test'

function App(props) {
  

    return (
        <React.Fragment>
            <Router>
                <QuestionProvider>
                    <SectionProvider>
                        <AppProvider>

                            <div className="site">
                                <Header logoName={logo} mailto="mail@mail.com" />
                                <LandingPageForm />
                            </div>

                        </AppProvider>
                    </SectionProvider>
                </QuestionProvider>
                
                <Switch>
                    <Route path="/signup" component={Test} />
                </Switch>
            </Router>
            {/* <Test/> */}
        </React.Fragment>

    );
}

export default App;