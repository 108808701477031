
import React from 'react'

const ButtonCard = (props) => {

    return (
        <div className="card-option">
            <input
                type={props.type}
                name={props.name}
                value={props.value}
                id={props.id}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
                onChange={props.onChange}
                onClick={props.onClick}
            />
            <label htmlFor={props.id}>
                {props.icon && (
                    <span className="card-icon">
                        {props.icon}
                    </span>
                )}
                {props.label && (
                    <span className="card-name">{props.label}</span>
                )}
            </label>
        </div>

    )
}

export default ButtonCard