import React, { useContext } from 'react'
import { Switch, Route,  Link } from "react-router-dom"
import { AppContext } from "../../contexts/AppContext"
import Page  from "../../pages/Page"

const Header = (props) => {
    const [page, setPage] = useContext(AppContext)
    return (
        <React.Fragment>
            {/* <header className={page.currentStep === 16 ? 'header-wrapper-hidden' : ''}> */}
            <header>
                <div className='header-wrapper'>
                    <ul className="left">
                        <li><Link to="/" className="logo">
                            <img src={props.logoName} alt="" /></Link></li>
                    </ul>

                    <ul className="right">
                        <li className="btn-contact"><a href="https://calendly.com/blank-space/discovery?month=2020-05">Schedule Meeting</a></li>
                        <li className="price"><div>$ <span>0</span></div></li>
                    </ul>

                    <div className="clear"></div>
                </div>
            </header>
            
        </React.Fragment>


    )
}

export default Header