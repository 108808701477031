import React, { useContext, useState } from "react"
import Checkbox from "../components/Checkbox/CheckBox"
import NotRequired from "../components/Button/NotRequired"
import { AppContext } from "../contexts/AppContext"
import { QuestionContext } from "../contexts/QuestionContext"
import { SectionContext } from "../contexts/SectionContext"
import { OptionContext } from "../contexts/OptionContext"
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Page8 = (props) => {

    const matches = useMediaQuery('(max-width:640px)');
    const [page, setPage] = useContext(AppContext)
    const [questions, setQuestions] = useContext(QuestionContext);
    // const [sections, setSections] = useContext(SectionContext)
    const [options, setOptions] = useState({ isChecked: false })
    const { option, required, selectFeature } = useContext(SectionContext);
    const [sections, setSections] = option;
    const [isActivated, setIsActivated] = required;
    const [selectionFeature, setSelectionFeature] = selectFeature;
    const [checkedState, setCheckedState] = useState({ isChecked: false})
    
    const _next = () => {
        let currentStep = page.currentStep
        currentStep = currentStep >= 15 ? 16 : currentStep + 1
        setPage({ currentStep: currentStep })
    }

    if (props.currentStep !== 8) {
        return null
    }

    
    var requireBtn
    if (checkedState.isChecked === false) {
        requireBtn = <NotRequired
            currentStep={page.currentStep}
            onClick={_next}
            label="Not Required" />
    } else {
        requireBtn = null
    }

    return (
        questions.filter(questions => questions.id === 8).map((question) => (
            <div key={question.id} className="question" /*style={{ transform: 'translate3d(0px, 0px, 0px)' }}*/>
                <h2>{question.label}</h2>
                <p style={{ paddingBottom: matches ? '3vh' : '6vh' }}></p>
                <div className="options-container">

                    {question.options.map((option) => (
                        <Checkbox
                            key={option.id}
                            type={question.type}
                            id={option.id}
                            name={option.selected}
                            value={option.selected}
                            //checked={option.selected || false}
                            onChange={(e) => {
                                let checked = e.target.checked
                                let index
                                // let isActived = e.target.checked
                                // setSections({isActivated: isActived})

                                if (option.id === option.position) {
                                    option.selected = checked
                                    setIsActivated({
                                        question8: checked
                                    })
                                    setCheckedState({
                                        isChecked: checked
                                    })
                                }

                                setOptions({ id: option.id, isChecked: !option.selected })
                                //console.log(sections)
                                console.log(sections)
                                option.sections.filter((sect) => sect.sectionId === parseInt(selectionFeature.isSelected)).map((section) => {
                                    if (option.selected && section.optionId === option.id) {


                                        const cost = option.subTotal + (section.price * section.multiplier)
                                        sections.push({ name: option.label, cost: cost })
                                        console.log(sections)

                                    } else {

                                        const cost = option.subTotal + (section.price * section.multiplier)
                                        index = sections.indexOf({ name: option.label, cost: cost })
                                        sections.splice(index, 1)
                                        console.log(sections)
                                    }

                                })
                            }}
                            icon={option.icon}
                            label={option.label}
                            description={option.description}

                        />
                    ))}


                    {requireBtn}

                </div>
            </div>
        ))
    )
}
export default Page8