import React, { useContext, useState } from "react"
import {
    Page,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
    Page13,
    Page14,
    Page15,
    Invoice,
    Signup
} from "../pages"
import NextButton from "../components/Button/NextButton"
import NotRequiredButton from "../components/Button/NotRequiredButton"
import SubmitButton from "../components/Button/SubmitButton"
import PrevArrow from "../components/Arrow/PrevArrow"
import { back, hero, nextarrow } from "../assets/IconLibrary"
import { AppContext } from "../contexts/AppContext"
import { QuestionContext } from "../contexts/QuestionContext"
import { SectionContext } from "../contexts/SectionContext"
import Footer from "../components/Footer/Footer"
import useMediaQuery from '@material-ui/core/useMediaQuery';


const LandingPageForm = (props) => {
    const [page, setPage] = useContext(AppContext)
    const matches = useMediaQuery('(max-width:640px)');
    const [questions, setQuestions] = useContext(QuestionContext)
    const { option, selected, checking, required } = useContext(SectionContext);
    const [sections, setSections] = option;
    const [selection, setSelection] = selected;
    const [checkButton, setCheckButton] = checking
    const [isActivated, setIsActivated] = required;

    const handleSubmit = event => {
        event.preventDefault()
        alert("Your registration detail")
    }


    const _next = (e) => {
        //e.preventDefault();
        let currentStep = page.currentStep
        currentStep = currentStep >= 15 ? 16 : currentStep + 1
        setPage({ currentStep: currentStep })
        
        
    }

    const _prev = () => {
        let currentStep = page.currentStep
        currentStep = currentStep <= 0 ? 0 : currentStep - 1
        setPage({ currentStep: currentStep })
    }


    const handleClick = (event) => {
        let index
        questions.filter(questions => questions.id === page.currentStep).map((question) => {
            question.options.map((option) => {
                option.sections.filter((sect) => sect.sectionId === parseInt(selection.isSelected)).map((section) => {
                    if (section.optionId === option.id) {

                        const cost = option.subTotal + (section.price * section.multiplier)
                        sections.push({ name: option.label, cost: cost })
                        console.log(sections)

                    } else {

                        const cost = option.subTotal + (section.price * section.multiplier)
                        index = sections.indexOf({ name: option.label, cost: cost })
                        sections.splice(index, 1)
                        console.log(sections)

                    }

                })
            })
        })

    }


    // const _invoice = () => {

    // 	if (page.currentStep === 16) {
    // 		const timer = setTimeout(() => {
    // 			 history.push('/signup')
    // 			// let currentStep = page.currentStep
    // 			// currentStep = currentStep >= 15 ? 16 : currentStep + 1

    // 			// setPage({ currentStep: currentStep })
    // 		}, 5000);
    // 		return () => clearTimeout(timer);
    // 	}
    // }

    //     const [value, setValue] = useState({ sum: 0 });
    //     const [options, setOptions] = useState({ id: 0, isChecked: false })
    //     const handleChange = (e) => {
    //         questions.filter(questions => questions.id === page.currentStep).map((question) => {
    //             question.options.map((option) => {

    //                 let checked = e.target.checked
    //                 if (option.id === option.position) {
    //                     option.selected = checked

    //                 }
    //                 setOptions({ id: option.id, isChecked: !option.selected })
    //                 console.log(questions)
    //                 option.sections.filter((sect) => sect.sectionId === parseInt(sections.isSelected)).map((section) => {
    //                     if (option.selected && section.optionId === option.id) {

    //                         setValue({ sum: value.sum + (section.price * section.multiplier) })
    //                         sections.sum = sections.sum + (section.price * section.multiplier)
    //                         console.log(sections.sum)

    //                     } else {
    //                         setValue({ sum: value.sum - (section.price * section.multiplier) })
    //                         sections.sum = sections.sum - (section.price * section.multiplier)
    //                         console.log(sections.sum)
    //                     }

    //                 })

    //             })
    //         })
    //     }

    //     const handleClick = (e) => {
    //         questions.filter(questions => questions.id === page.currentStep).map((question) => {
    //             question.options.map((option) => {
    //                 let checked = e.target.value
    //                 if (option.id === option.postion)
    //                     option.isChecked = checked
    //                setSections({ isSelected: checked })

    //                option.sections.filter((sect) => sect.sectionId === parseInt(sections.id)).map((section) => {
    //                    if (option.isChecked && section.optionId === option.id) {
    //                        sections.sum = sections.sum + (section.price * section.multiplier)
    //                    } else {
    //                        sections.sum = sections.sum - (section.price * section.multiplier)
    //                    }
    //                })
    //             })
    //         })
    //     }


    // const result = Object.values(questions)
    let nextButton = null

    //console.log(sections.isActivated)
    questions.filter(questions => questions.id === page.currentStep).map((question) => {
        question.options.map((option) => {
        
            if ((isActivated.question1 === true && page.currentStep === 1)
                || (isActivated.question2 === true && page.currentStep === 2)
                || (isActivated.question3 === true && page.currentStep === 3)
                || (isActivated.question4 === true && page.currentStep === 4)
                || (isActivated.question5 === true && page.currentStep === 5)
                || (isActivated.question6 === true && page.currentStep === 6)
                || (isActivated.question7 === true && page.currentStep === 7)
                || (isActivated.question8 === true && page.currentStep === 8)
                || (isActivated.question9 === true && page.currentStep === 9)
                || (isActivated.question10 === true && page.currentStep === 10)
                || (isActivated.question11 === true && page.currentStep === 11)
                || (isActivated.question12 === true && page.currentStep === 12)
                || (isActivated.question13 === true && page.currentStep === 13)
                || (isActivated.question15 === true && page.currentStep === 14)
                ) {
                nextButton = <NextButton
                    currentStep={page.currentStep}
                    onClick={_next}
                    label="Next Question"
                    icon={nextarrow}
                />
            } else if (isActivated.question14 === true && page.currentStep === 15) {
                nextButton = <SubmitButton
                    currentStep={page.currentStep}
                    onClick={_next}
                    label="Submit"
                    color={{ backgroundColor: '#3677FF' }}
                />
            } else if (page.currentStep === 1
                || page.currentStep === 15
                ) {

                nextButton = <NextButton
                    currentStep={page.currentStep}
                    onClick={_next}
                    label="Next Question"
                    icon={nextarrow}
                    style={{ pointerEvents: page.currentStep === 15 || page.currentStep === 1 || page.currentStep === 2 ? 'none' : 'auto', backgroundColor: page.currentStep === 15 || page.currentStep === 1 || page.currentStep === 2 ? '#B7B7B7' : '#000000'}}
                />
                }else if (page.currentStep === 2){

                    nextButton = <NextButton
                    currentStep={page.currentStep}
                    onClick={_next}
                    label="Next Question"
                    icon={nextarrow}
                    style={{ pointerEvents: page.currentStep === 2  ? 'none' : 'auto', backgroundColor: page.currentStep === 2 ? '#B7B7B7' : '#000000'}}
                />
            } else {
                nextButton = <NotRequiredButton
                    currentStep={page.currentStep}
                    onClick={_next}
                    label="Not Required"
                    icon={nextarrow}
                />
            }
        })
    })

    return (
        <React.Fragment>
            <div className="wraper" style={{
                backgroundImage: (page.currentStep === 0 ? `url(${hero})` : 'none'),
                backgroundPosition: (page.currentStep === 0 ? 'bottom right' : 'none'),
                backgroundSize: (page.currentStep === 0 ? '354.05px' : 'none'),
                backgroundRepeat: (page.currentStep === 0 ? 'no-repeat' : 'none'),
                overflowY: (page.currentStep === 16 ? 'auto' : 'hidden'),
                backgroundColor: (page.currentStep === 16 ? '#F7F7F7' : '#ffffff') 
            }}>
                <div className="calculator-container" style={{ paddingLeft: (page.currentStep === 0 || page.currentStep === 16 ? '0vw' : ''), paddingTop: (page.currentStep === 16 ? '11vh' : '') }}>
                    <div className="content">
                        <div className={page.currentStep === 16 ? 'no-display-arrow' : 'display-arrow'}>
                            <PrevArrow
                                currentStep={page.currentStep}
                                onClick={_prev}
                                icon={back}
                            />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="questions">
                                <Page
                                    currentStep={page.currentStep}
                                />
                                <Page1
                                    currentStep={page.currentStep}
                                //onClick={handleClick}
                                />
                                <Page2
                                    currentStep={page.currentStep}
                                //onClick={handleClick}

                                />
                                <Page3
                                    currentStep={page.currentStep}
                                //onClick={handleClick}
                                />
                                <Page4
                                    currentStep={page.currentStep}
                                // onClick={handleClick}
                                />
                                <Page5
                                    currentStep={page.currentStep}
                                />
                                <Page6
                                    currentStep={page.currentStep}
                                //onChange={handleChange}
                                />
                                <Page7
                                    currentStep={page.currentStep}
                                // onChange={handleChange}
                                />
                                <Page8
                                    currentStep={page.currentStep}
                                // onChange={handleChange}
                                />
                                <Page9
                                    currentStep={page.currentStep}
                                //onChange={handleChange}
                                />
                                <Page10
                                    currentStep={page.currentStep}
                                />
                                <Page11
                                    currentStep={page.currentStep}
                                //onChange={handleChange}
                                />
                                <Page12
                                    currentStep={page.currentStep}
                                //onChange={handleChange}
                                />
                                <Page13
                                    currentStep={page.currentStep}
                                //onChange={handleChange}

                                />
                                <Page15
                                    currentStep={page.currentStep}
                                //onChange={handleChange}
                                />
                                <Page14
                                    currentStep={page.currentStep}
                                //onChange={handleChange}
                                />
                                <Invoice
                                    currentStep={page.currentStep}
                                />
                                {/* <Signup
                                    currentStep={page.currentStep}
                                /> */}
                            </div>
                        </form>
                    </div>
                    <div className={page.currentStep === 0 || page.currentStep === 16 ? 'mobile-sticky-hidden' : 'mobile-sticky'}>
                        <div className={page.currentStep === 0 || page.currentStep === 16 ? 'container-porgress-bars' : "container-porgress-bar"}>
                            <div className="progress-bar--tooltip">
                                <div className="progress-bar--numbers">
                                    <span className="current">Step {page.currentStep}</span><span className="total">out of 15</span>
                                </div>
                                <div className="progress-bar">
                                    <div className="bar" style={{ width: (page.currentStep === 1 ? '6.66%' : `${page.currentStep * 6.66}%`) }}>
                                        {/* <div className="tooltip"><span className="current">1</span> / <span className="total">15</span></div>*/}
                                    </div>
                                </div>
                            </div>

                            <div className="button-progress-bar">
                                {/* <NextButton
                                    currentStep={page.currentStep}
                                    onClick={_next}
                                    label="Next Question"
                                    icon={nextarrow}
                                /> */}
                                {nextButton}

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {page.currentStep === 0 || page.currentStep === 16 ? null : <Footer />}
        </React.Fragment>
    )
}

export default LandingPageForm